<template>
    <div>
        <card-header title="New Room Type" icon="fa-plus"/>

        <card-body>
            <room-type-form :room="newRoom"/>
        </card-body>

        <card-footer class="pt-3 pb-3 has-text-right">
            <b-button type="is-primary" size="is-small" :disabled="!newRoom.title" @click="submit"><i class="fas fa-plus mr-3"></i>New Room Type</b-button>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardFooter from "../../TIER/components/CardFooter";
    import CardBody from "../../TIER/components/CardBody";

    import RoomTypeForm from "./RoomTypeForm";

    import {client as http} from '../../http_client';

    export default {
        props: ['card', 'props'],
        components: {RoomTypeForm, CardBody, CardFooter, CardHeader},
        data: function () {
            return {
                newRoom: {},
            };
        },
        methods: {
            submit() {
                this.$emit('loading', true);
                http.post('/api/trips/hotels/room_types', {hotel_id: this.props.hotelId, ...this.newRoom}, {force: true}).then(response => {
                    this.$reloadCard('trip-hotel', {hotelId: this.props.hotelId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {
                    console.log('An error occurred.');
                    this.$emit('loading', false);
                });
            }
        },
        mounted() {
            this.$saveAlert(true, 'Are you done creating this new room type? Unsaved changes will be lost.');
        }
    };
</script>
